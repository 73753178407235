import React, { Component } from "react"
import { Link } from "gatsby"
import cn from "classnames"
import Logo from "../../images/logo-white.svg"
import MobileNavIcon from "../../images/menu-icon.svg"
import MobileNavCloseIcon from "../../images/menu-cancel.svg"
import INDICATOR from "../../images/header-indicator.svg"
import style from "./header.module.css"
import { StaticQuery, graphql } from 'gatsby';


class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false,
      mobileSolutionsMenuOpen: false,
      headerSolutionsMenuOpen: false,
    }
  }

  handleToggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen })
  }

  handleToggleMobileSolutionsMenu = () => {
    this.setState({
      mobileSolutionsMenuOpen: !this.state.mobileSolutionsMenuOpen,
    })
  }

  handleShowHeaderSolutionsMenu = () => {
    this.setState({
      headerSolutionsMenuOpen: true,
    })
  }

  handleHideHeaderSolutionsMenu = () => {
    this.setState({
      headerSolutionsMenuOpen: false,
    })
  }

  handleToggleHeaderSolutionsMenu = () => {
    this.setState({
      headerSolutionsMenuOpen: !this.state.headerSolutionsMenuOpen,
    })
  }

  render() {
    const {
      mobileMenuOpen,
      mobileSolutionsMenuOpen,
      headerSolutionsMenuOpen,
    } = this.state

    const { appServer } = this.props.data.site.siteMetadata;

    return (
      <>
        <header className={style.root}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <div className={style.headerContent}>
                  {/* Header Logo */}
                  <div className={style.headerLogo}>
                    <Link className={style.headerLogoLink} to="/">
                      <img src={Logo} alt="Logo" />
                    </Link>
                  </div>

                  {/* Header Desktop Menu */}
                  <nav className={style.headerNavigation}>
                    <div
                      className={cn(
                        style.headerNavigationLink,
                        style.headerSolutionsLink
                      )}
                      onMouseEnter={this.handleShowHeaderSolutionsMenu}
                    >
                      Solutions
                    </div>

                    <Link className={style.headerNavigationLink} to="/about">
                      About Us
                    </Link>

                    <a
                      className={style.headerNavigationLink}
                      href="http://docs.shipamax.com/"
                    >
                      Docs
                    </a>
                  </nav>
                  <div className={style.headerContact}>
                    <Link
                      className={cn(
                        style.headerContactLink,
                        style.getInTouchLink
                      )}
                      to="/contact"
                    >
                      Get in touch
                    </Link>
                    <a className={style.headerContactLink} href={`${appServer}/`}>
                      Login
                    </a>
                  </div>
                  <div
                    className={style.mobileNavToggle}
                    onClick={this.handleToggleMobileMenu}
                  >
                    {mobileMenuOpen === false ? (
                      <img src={MobileNavIcon} alt="Mobile Menu Icon" />
                    ) : (
                        <img
                          src={MobileNavCloseIcon}
                          alt="Mobile Menu Close Icon"
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* Header Mobile Menu */}
            {mobileMenuOpen && (
              <nav
                className={cn(
                  style.mobileNavMenu,
                  mobileMenuOpen && style.mobileNavMenuOpen
                )}
              >
                <div
                  className={cn(
                    style.mobileNavMenuLink,
                    style.mobileSolutionsLink
                  )}
                  to=""
                  onClick={this.handleToggleMobileSolutionsMenu}
                >
                  Solutions
                </div>

                {/* Mobile Solutions Sub Menu */}
                {mobileSolutionsMenuOpen && (
                  <div className={style.mobileSolutionsMenu}>
                    <Link
                      className={style.mobileSolutionsMenuLink}
                      to="/freight-forwarders"
                    >
                      Freight Forwarders
                    </Link>
                    <Link
                      className={style.mobileSolutionsMenuLink}
                      to="/chartering-teams"
                    >
                      Chartering Teams
                    </Link>
                    <Link
                      className={style.mobileSolutionsMenuLink}
                      to="/ship-brokers"
                    >
                      Shipbrokers
                    </Link>
                  </div>
                )}
                <Link className={style.mobileNavMenuLink} to="/about">
                  About Us
                </Link>
                <a
                  className={style.mobileNavMenuLink}
                  href="http://docs.shipamax.com/"
                >
                  Docs
                </a>
                <Link
                  className={cn(
                    style.mobileNavMenuLink,
                    style.mobileNavMenuContactLink
                  )}
                  to="/contact"
                >
                  Get in touch
                </Link>
                <Link className={style.mobileNavMenuLink} to="/login">
                  Login
                </Link>
              </nav>
            )}
          </div>

          {/* Header Solutions Sub Menu */}
          {headerSolutionsMenuOpen && (
            <>
              <nav
                className={style.headerSolutionsMenu}
                onMouseLeave={this.handleHideHeaderSolutionsMenu}
              >
                <div className={style.headerSolutionsMenuWrap}>
                  <img
                    className={style.headerSolutionsIcon}
                    src={INDICATOR}
                    alt="Solution Indicator"
                  />
                  <Link
                    className={cn(
                      style.headerSolutionsMenuLink,
                      style.headerFreightLink
                    )}
                    to="/freight-forwarders"
                  >
                    Freight Forwarders
                    </Link>
                </div>

                <Link
                  className={style.headerSolutionsMenuLink}
                  to="/chartering-teams"
                >
                  Chartering Teams
                  </Link>
                <Link
                  className={style.headerSolutionsMenuLink}
                  to="/ship-brokers"
                >
                  Shipbrokers
                  </Link>
              </nav>
            </>
          )}
        </header>
      </>
    )
  }
}

Header.propTypes = {}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            appServer
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
