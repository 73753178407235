import React from "react"
import PropTypes from "prop-types"
import Header from "../Header/index"
import Footer from "../Footer/index"
import CookieConsent from "react-cookie-consent"
import style from "./layout.module.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />

      {/* Cookie Banner */}
      <CookieConsent
        cookieName="cookieconsent_status"
        containerClasses={style.cookieConsent}
        buttonText="x"
        buttonClasses={style.cookieConsentBtn}
        contentClasses={style.cookieConsentContent}
      >
        Shipamax uses cookies to enable a better experience.{" "}
        <a className={style.cookieLink} href="https://www.cookiesandyou.com/">Learn more.</a>
      </CookieConsent>
      
      <main className={style.main}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
