import React from "react"
import { Link } from "gatsby"
import LogoDark from "../../images/logo-dark.svg"
import LinkedIn from "../../images/linkedin.svg"
import Twitter from "../../images/twitter.svg"
import AngelList from "../../images/angel-list.svg"
import ToTopArrow from "../../images/arrow-top.svg"
import style from "./footer.module.css"

const Footer = () => {
  return (
    <>
      <footer className={style.root}>
        <div className={style.mobileFooter}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <div className={style.footerLogo}>
                  <Link className={style.headerLogoLink} to="/">
                    <img src={LogoDark} alt="Logo" />
                  </Link>
                </div>
                <p className={style.footerSubtitle}>
                  Accelerating the next
                  <br /> generation of supply chain
                </p>
                <div className={style.footerContactInfo}>
                  <a
                    href="mailto:contact@shipamax.com"
                    className={style.footerContactInfoLink}
                  >
                    contact@shipamax.com
                  </a>
                  <address className={style.footerContactInfoAddress}>
                    25 Finsbury Circus,
                    <br />
                    London, EC2M 7EE, UK
                  </address>
                  <div className={style.footerSocials}>
                    <a
                      className={style.linkedin}
                      href="https://www.linkedin.com/company/shipamax/"
                    >
                      <img src={LinkedIn} alt="Linkedin Logo" />
                    </a>
                    <a
                      className={style.twitter}
                      href="https://twitter.com/shipamax?lang=en-gb"
                    >
                      <img src={Twitter} alt="Twitter Logo" />
                    </a>
                    <a
                      className={style.angellist}
                      href="https://angel.co/company/shipamax/jobs"
                    >
                      <img src={AngelList} alt="Angel List Logo" />
                    </a>
                  </div>
                </div>
                <div className={style.footerLinks}>
                  <nav className={style.footerNav}>
                    <Link
                      className={style.footerNavLink}
                      to="/freight-forwarders"
                    >
                      Freight Forwarders
                    </Link>
                    <Link className={style.footerNavLink} to="/ship-brokers">
                      Ship Brokers
                    </Link>
                    <Link
                      className={style.footerNavLink}
                      to="/chartering-teams"
                    >
                      Chartering Teams
                    </Link>
                    <Link className={style.footerNavLink} to="/about">
                      About Us
                    </Link>
                    <a
                      className={style.footerNavLink}
                      href="http://docs.shipamax.com/"
                    >
                      Docs
                    </a>
                  </nav>
                  <nav className={style.footerInfo}>
                    <a
                      className={style.footerInfoLink}
                      href="https://angel.co/company/shipamax/jobs"
                    >
                      Jobs
                    </a>
                    <a
                      className={style.footerInfoLink}
                      href="https://server.shipamax.com/termsandconditions.txt"
                    >
                      Terms & Conditions
                    </a>
                    <a
                      className={style.footerInfoLink}
                      href="https://server.shipamax.com/privacypolicy.txt"
                    >
                      Privacy Policy
                    </a>
                  </nav>
                </div>
                <div className={style.topArrow}>
                  <Link className={style.topArrowLink} to="/">
                    <img src={ToTopArrow} alt="Top Arrow" />
                  </Link>
                </div>
                <div className={style.copyright}>
                  <p>© {new Date().getFullYear()} Shipamax Ltd.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.desktopFooter}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <div className="row">
                  <div className="col s12 m6">
                    <div className={style.desktopFooterLogo}>
                      <Link className={style.headerLogoLink} to="/">
                        <img src={LogoDark} alt="Logo" />
                      </Link>
                    </div>
                    <p className={style.desktopFooterSubtitle}>
                      Accelerating the next
                      <br /> generation of supply chain
                    </p>
                    <div className={style.desktopFooterContactInfo}>
                      <a
                        href="mailto:contact@shipamax.com"
                        className={style.desktopFooterContactInfoLink}
                      >
                        contact@shipamax.com
                      </a>
                      <address
                        className={style.desktopFooterContactInfoAddress}
                      >
                        25 Finsbury Circus,
                        <br />
                        London, EC2M 7EE, UK
                      </address>
                    </div>
                  </div>
                  <div className="col s12 m6">
                    <div className={style.desktopFooterLinks}>
                      <nav className={style.desktopFooterNav}>
                        <Link
                          className={style.desktopFooterNavLink}
                          to="/freight-forwarders"
                        >
                          Freight Forwarders
                        </Link>
                        <Link
                          className={style.desktopFooterNavLink}
                          to="/ship-brokers"
                        >
                          Ship Brokers
                        </Link>
                        <Link
                          className={style.desktopFooterNavLink}
                          to="/chartering-teams"
                        >
                          Chartering Teams
                        </Link>
                        <Link
                          className={style.desktopFooterNavLink}
                          to="/about"
                        >
                          About Us
                        </Link>
                        <a
                          href="http://docs.shipamax.com/"
                          className={style.desktopFooterNavLink}
                        >
                          Docs
                        </a>
                        <div className={style.desktopTopArrow}>
                          <Link className={style.desktopTopArrowLink} to="/">
                            <img src={ToTopArrow} alt="Top Arrow" />
                          </Link>
                        </div>
                      </nav>
                      <nav className={style.desktopFooterInfo}>
                        <a
                          className={style.desktopFooterInfoLink}
                          href="https://angel.co/company/shipamax/jobs"
                        >
                          Jobs
                        </a>
                        <a
                          className={style.desktopFooterInfoLink}
                          href="https://server.shipamax.com/termsandconditions.txt"
                        >
                          Terms & Conditions
                        </a>
                        <a
                          className={style.desktopFooterInfoLink}
                          href="https://server.shipamax.com/privacypolicy.txt"
                        >
                          Privacy Policy
                        </a>
                        <div className={style.desktopFooterSocials}>
                          <a
                            className={style.linkedin}
                            href="https://www.linkedin.com/company/shipamax/"
                          >
                            <img src={LinkedIn} alt="Linkedin Logo" />
                          </a>
                          <a
                            className={style.twitter}
                            href="https://twitter.com/shipamax?lang=en-gb"
                          >
                            <img src={Twitter} alt="Twitter Logo" />
                          </a>
                          <a
                            className={style.angellist}
                            href="https://angel.co/company/shipamax/jobs"
                          >
                            <img src={AngelList} alt="Angel List Logo" />
                          </a>
                        </div>
                        <div className={style.desktopCopyright}>
                          <p>© {new Date().getFullYear()} Shipamax Ltd.</p>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
