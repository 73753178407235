import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import HomePage from "./home"
import Data from "../data/data"
import "../styles/index.css"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Data.metaData.siteTitle}</title>
        <meta name="description" content={Data.metaData.description.homepage} />
      </Helmet>
      <HomePage />
    </Layout>
  )
}

export default IndexPage
